import "/src/css/main.css";

import {Modal, Drawer, Accordion, Dropdown} from 'flowbite';



$(window).on('load', function () {

    // const $targetEl = document.getElementById('offcanvas');
    // const drawer = new Drawer($targetEl);
    // drawer.show();

    var windowWidth = $(window).width();

    renderOffcanvasAccordion();
    $('#offcanvas').show();

    /**
     * UTILITIES
     * -----------------------------------------------------------------------------------------------------------------
     */
    String.prototype.hashCode = function () {
        var hash = 0, i, chr;
        if (this.length === 0)
            return hash;

        for (i = 0; i < this.length; i++) {
            chr = this.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    /**
     * TOOLTIPS
     */

    /**
     * COOKIES
     * -----------------------------------------------------------------------------------------------------------------
     */
    function cookieGet(name) {
        name += "="
        const decodedCookie = decodeURIComponent(document.cookie),
            ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return 0;
    }

    function cookieSet(name, value, attr = {expires: Infinity, path: "/"}) {
        let d = new Date(), expires
        d.setTime(d.getTime() + (attr.expires * 24 * 60 * 60 * 1000))
        expires = d.toUTCString()
        //console.log(`${name}=${value};expires=${expires};path=${attr.path}`)
        document.cookie = `${name}=${value};expires=${expires};path=${attr.path}`

    }

    function cookieRemove(name, attr = {path: "/"}) {
        this.set(name, "", 0, attr.path)
    }

    function cookieGetAll() {
        const ca = decodeURIComponent(document.cookie).split(';'),
            arr = []
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            let item = c.substring(name.length, c.length).split('=')
            arr[item[0]] = item[1]
        }
        return arr
    }

    /**
     * SEARCH BOXES
     * -----------------------------------------------------------------------------------------------------------------
     */
    function triggerSearchBoxes(){
        $('#label-search-large, #label-search-small, #label-result').click(function(){
            $(this).hide();
            $('#input-search-large, #input-search-small, #input-result').focus();
        });
        $('#input-search-large, #input-search-small, #input-result').focusout(function(){
            var val = $(this).val();
            if(!val)
                $('#label-search-large, #label-search-small, #label-result').show();
        });

        $('label[data-searchbutton="label-search-button"]').click(function(){
            var width = $(this).width();
            var height = $(this).outerHeight();

            $(this).hide();
            var id = $(this).attr('id').replace('label','input');

            $('#'+id).show().width(width).css('height',height+'px').focus().focusout(function(){
                var val = $(this).val();
                var id_ = id.replace('input','label');
                if(!val) {
                    $(this).hide();
                    $('#' + id_).show();
                }
            });
        });

        $('form[data-type="search"]').each(function(){
            var height = $(this).find('label').outerHeight();
            $(this).css('height',height+'px');
        });
    }

    triggerSearchBoxes();

    /**
     * OFF CANVAS ACCORDION
     * -----------------------------------------------------------------------------------------------------------------
     */
    function renderOffcanvasAccordion(){

        const accordionElement = document.getElementById('accordion-example');

        // create an array of objects with the id, trigger element (eg. button), and the content element
        var accordionId = '#accordion-menu';
        const accordionItems = [];

        $(accordionId).children('h5').each(function(){

            var id = $(this).attr('id'),
                accordionHandle = accordionId.replace('#', ''),
                headingName = id.replace(accordionHandle,''),
                count = headingName.replace('-heading-','');

            accordionItems.push({
                id: id,
                triggerEl: document.querySelector('#' + id),
                targetEl: document.querySelector(accordionId + '-body-' + count),
                active: false
            });
        });

        // options with default values
        const options = {
            alwaysOpen: true,
            activeClasses: 'bg-transparent', // Deze mogen niet leeg zijn
            inactiveClasses: 'bg-transparent', // Deze mogen niet leeg zijn
            onOpen: (item) => {
            },
            onClose: (item) => {
            },
            onToggle: (item) => {
            },
        };

        // instance options object
        const instanceOptions = {
            id: accordionId,
            override: true
        };

        const accordion = new Accordion(accordionElement, accordionItems, options, instanceOptions);
    }

    /**
     * MATCH HEIGHT DIVS (kijk bij pricetables)
     * -----------------------------------------------------------------------------------------------------------------
     */
    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    var groupIds = [];
    $('div[data-matchheight]').each(function(){
        if(!isInArray($(this).data('matchheight'), groupIds)){
            groupIds.push($(this).data('matchheight'));
        }
    });

    var i = 0,
        height = [];
    for(i; i<groupIds.length; i++){
        $('[data-matchheight="'+groupIds[i]+'"]').each(function(){
            height[groupIds[i]] = height[groupIds[i]] > 0 ? height[groupIds[i]] : 0;
            height[groupIds[i]] = $(this).outerHeight() > height[groupIds[i]] ? $(this).outerHeight() : height[groupIds[i]];
        });
    }
    i=0;

    for(i; i<groupIds.length; i++){
        $('[data-matchheight="'+groupIds[i]+'"]').each(function(){
            var onSmall = $(this).data('matchheightsmall');
            if(onSmall || windowWidth > 640) {
                $(this).css('height', Math.round(height[groupIds[i]]) + 'px');
            }
        });
    }

    /**
     * MATCH WIDTH DIVS (kijk bij filters)
     * -----------------------------------------------------------------------------------------------------------------
     */
    var groupIds = [];
    $('div[data-matchwidth]').each(function(){
        if(!isInArray($(this).data('matchwidth'), groupIds)){
            groupIds.push($(this).data('matchwidth'));
        }
    });


    var i = 0,
        width = [];
    for(i; i<groupIds.length; i++){
        $('[data-matchwidth="'+groupIds[i]+'"]').each(function(){
            width[groupIds[i]] = width[groupIds[i]] > 0 ? width[groupIds[i]] : 0;
            width[groupIds[i]] = $(this).outerWidth() > width[groupIds[i]] ? $(this).outerWidth() : width[groupIds[i]];
        });
    }
    i=0;

    for(i; i<groupIds.length; i++){
        $('[data-matchwidth="'+groupIds[i]+'"]').each(function(){
            var onSmall = $(this).data('matchwidthsmall');
            if(onSmall || windowWidth > 640) {
                $(this).css('width', Math.round(width[groupIds[i]]) + 'px');
            }
        });
    }

    /*
     * FILTERS
     * -----------------------------------------------------------------------------------------------------------------
     */
    // $('[data-filter]').each(function(){
    //     var id = $(this).attr('id');
    //     const $targetEl = document.getElementById(id);
    //     const $triggerEl = document.getElementById(id+'-button');
    //
    //     const options = {
    //         placement: 'bottom',
    //         triggerType: 'click',
    //         offsetSkidding: 0,
    //         offsetDistance: 10,
    //         delay: 300,
    //         ignoreClickOutsideClass: true,
    //         onHide: () => {
    //             // $($triggerEl).css('border-bottom-left-radius','');
    //             // $($triggerEl).css('border-bottom-right-radius','');
    //             // $($triggerEl).css('border-bottom','');
    //         },
    //         onShow: () => {
    //             // $($triggerEl).css('border-bottom-left-radius','0');
    //             // $($triggerEl).css('border-bottom-right-radius','0');
    //             // $($triggerEl).css('border-bottom','0');
    //         }
    //     };
    //
    //     const instanceOptions = {
    //         id: id,
    //         override: true
    //     };
    //
    //     new Dropdown($targetEl, $triggerEl, options, instanceOptions);
    // });



    /**
     * CALLOUTS & MODALS
     * -----------------------------------------------------------------------------------------------------------------
     */
    function closeCallout(calloutID) {
        var calloutElement = $('#' + calloutID);
        calloutElement.addClass('hidden opacity-0');
    }

    function openCallout(calloutID) {
        var calloutElement = $('#' + calloutID);
        calloutElement.removeClass('hidden opacity-0');
    }

    $('a[data-opencallout]').click(function (e) {
        e.preventDefault();

        var calloutID = $(this).data('opencallout');
        openCallout(calloutID);
    });

    $('a[data-closecallout]').click(function (e) {
        e.preventDefault();

        // search for parent
        var calloutParent = $(this).parents("div[data-type='callout']");
        var calloutID = $(calloutParent).attr('id');
        closeCallout(calloutID);
    });

    $("div[data-type='callout']").each(function () {
        var thisEl = $(this),
            thisID = $(this).attr('id');

        closeCallout(thisID); // hide all call outs
        $(this).appendTo('body'); // Move the callout to the bottom of the body

        // //
        // //
        // // // Set vars based on data attributes inside callout
        var delay = thisEl.data('delay'),
            showBasedOnDate = getShowBasedOnDate(thisEl),
            showBasedOnCookie = getShowBasedOnCookie(thisEl),
            trigger = thisEl.data('trigger');

        if (trigger === 'click')
            return;

        if (!showBasedOnDate)
            return;

        if (!showBasedOnCookie)
            return;

        delay = delay ? delay : 0;
        setTimeout(function () {
            // open callout
            openCallout(thisEl.attr('id'));
            setCookieBasedOnShow(thisEl);
        }, delay * 1000);

    });

    $("div[data-type='modal']").each(function () {
        var thisEl = $(this),
            thisID = $(this).attr('id'),
            thisButton = $(this).find('button');

        $(this).appendTo('body'); // Move the modal to the bottom of the body

        //var backdropClasses = 'bg-gray-900/50 fixed inset-0 z-40';
        const options = {
            // placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses:
                'bg-gray-900/50 fixed inset-0 z-40',
            closable: true,
            // onHide: () => {
            //     console.log('modal is hidden');
            // },
            onShow: () => {
                // we gaan de drawer sluiten, als die eventueel open staat


                // console.log('modal is shown');
            },
            // onToggle: () => {
            //     console.log('modal has been toggled');
            // },
        };

        const instanceOptions = {
            id: thisID,
            override: true
        };

        const $targetModal = document.getElementById(thisID);
        const modal = new Modal($targetModal, options, instanceOptions);
        thisButton.click(function () {
            modal.hide();
        });

        // get the trigger
        $('[data-modal-open="' + thisID + '"]').click(function () {
            modal.toggle();
            // const $targetEl = document.getElementById('offcanvas');
            // const drawer = new Drawer($targetEl);
            // if (drawer.isVisible()) {
            //     console.log('2');
            //     $('div[modal-backdrop]').hide();
            // }
        });

        thisEl.find('[data-close]').click(function () {
            modal.hide();
        });

        var delay = thisEl.data('delay'),
            showBasedOnDate = getShowBasedOnDate(thisEl),
            showBasedOnCookie = getShowBasedOnCookie(thisEl),
            trigger = thisEl.data('trigger');

        if (trigger === 'click')
            return;

        if (!showBasedOnDate)
            return;

        if (!showBasedOnCookie)
            return;

        delay = delay ? delay : 1;
        setTimeout(function () {
            // open modal
            modal.show();
            setCookieBasedOnShow(thisEl);
        }, delay * 1000);

    });

    function getShowBasedOnDate(element) {
        var fromDate = element.data('from'),
            tillDate = element.data('till'),
            today = new Date();

        today.setHours(0, 0, 0, 0);
        if (fromDate) {
            var fromDate = new Date(fromDate);
            fromDate.setHours(0, 0, 0, 0);
        }
        if (tillDate) {
            var tillDate = new Date(tillDate);
            tillDate.setHours(0, 0, 0, 0);
        }

        var showBasedOnDate = true;
        if (fromDate && fromDate > today)
            showBasedOnDate = false;

        if (tillDate && tillDate < today)
            showBasedOnDate = false;

        return showBasedOnDate;
    }

    function getShowBasedOnCookie(element) {
        var show = element.data('show'),
            id = element.attr('id');

        if (show == 'never')
            return;

        var cookieName = 'medotsites-' + String(id + show).hashCode();

        if (!cookieGet(cookieName))
            return true;

        return false;
    }

    function setCookieBasedOnShow(element) {
        var show = element.data('show'),
            id = element.attr('id');

        if (show == 'never')
            return false;

        var cookieName = 'medotsites-' + String(id + show).hashCode();

        var nrOfDays = new Array();
        nrOfDays['once'] = 365;
        nrOfDays['onceday'] = 1;
        nrOfDays['onceweek'] = 7;
        nrOfDays['oncemonth'] = 31;

        if (nrOfDays[show])
            cookieSet(cookieName, 1, {expires: nrOfDays[show]});
    }


    /**
     * CAROUSELS
     * -----------------------------------------------------------------------------------------------------------------
     */
     $('div[data-carousel]').each(function(){
        var wrapper = $(this).children().first(),
            height = 0,
            pictureHeight = 0,
            columnGap = 24,
            extraMargin = 4;
                          
        $(this).find('picture').each(function(){
            pictureHeight = $(this).innerHeight();    
            height = pictureHeight;
        });
        $(this).find('div[data-carousel-item-text]').each(function(){
            var iH = $(this).innerHeight();
            height = iH > height ? iH : height;
        });
        
        // if SMALL or MEDIUM
        if ($(window).width() < 1024) {
            var height = height + pictureHeight + columnGap + extraMargin;
        }
        
        wrapper.css("height", height+'px');
        $(this).css("height", height+'px');
     });


    /**
     * FORMS
     * -----------------------------------------------------------------------------------------------------------------
     */

    // Het bestelformulier niet versturen als we op ENTER klikken
    $(document).on('keypress',function(e) {
        if(e.which == 13) {
            var form = $('#orderform');
            if(form)
                return false;
        }
    });

    // We willen dat de a de submit triggert.
    $('a[data-formsubmit]').click(function(e){
        console.log('a[data-formsubmit]');
        e.preventDefault();
        var form = $('#'+$(this).data('formsubmit'));
        var button = $('#'+$(this).data('formsubmit')+'-submitbutton');

        if(form && button)
            $(button).click();
    });

    $('#stripe-payments-submit-link-orderform').bind("click touch", function(e){
        console.log('a[#stripe-payments-submit-link-orderform]');
        e.preventDefault();
        // showSpinner();

        var form = $('#orderform');
        var validated = validateForm(form);
        if(!validated.success){
            showAlert('error', validated.msg);
        } else {
            var buttonId = $(this).data('buttonid'),
                formId = buttonId.replace('stripe-payments-submit-button-','');

            // Card errors tonen
            $('#card-error-'+formId).bind("DOMSubtreeModified",function(){
                var error = $('#card-errors-'+formId).text();
                if(error)
                    showAlert('error', error);
            });

            // iDeal errors tonen
            $('#ideal-error-message-'+formId).bind("DOMSubtreeModified",function(){
                var error = $('#ideal-error-message-'+formId).text();
                if(error)
                    showAlert('error', error);
            });

            // focusable error fix
            $(form).find('[name="address[name]"]').removeAttr('required');
            $(form).find('[name="address[line1]"]').removeAttr('required');
            $(form).find('[name="address[city]"]').removeAttr('required');
            $(form).find('[name="address[state]"]').removeAttr('required');
            $(form).find('[name="address[zip]"]').removeAttr('required');
            $(form).find('[name="address[country]"]').removeAttr('required');

            $('#'+buttonId).click();

            form.on('submit', function(e){
            });
        }
    });

    function validateForm(form){
        // Validate fields
        var form = $(form),
            hasErrors = false,
            formId = form.attr('id'),
            obj_Messages = JSON.parse($('#formmessages').html()),
            toReturn = {};

        if(window.recaptchaready != undefined && $('#'+formId+'_recaptcha').length && grecaptcha.getResponse() == "") {
            $('#'+formId+'_recaptcha').addClass('c-error');
            toReturn = {'success':false, 'msg': obj_Messages.emptycheck};

            return toReturn;
        } else {

            if(window.recaptchaready != undefined){

                if($('#'+formId+'_recaptcha').length)
                    $('#'+formId+'_recaptcha').removeClass('c-error');

            }

            $('#'+formId+' *').filter(':input').each(function(){
                if($(this).data('required') !== undefined){
                    var parent = $(this).parent();
                    var parentsShippingContainer = $(this).parents('.shippingAddressContainer');

                    $(this).on('change', function(){
                        parent.removeClass('c-error');
                    });

                    if (parentsShippingContainer.length == 1 && parentsShippingContainer.hasClass('hidden')) {
                        parent.removeClass('c-error');
                        if(parent.hasClass('input-group'))
                            parent.prev().removeClass('c-error');
                    } else {
                        if(!$(this).val()){
                            hasErrors = true;

                            parent.addClass('c-error');
                            if(parent.hasClass('input-group'))
                                parent.prev().addClass('c-error');
                        } else {
                            parent.removeClass('c-error');
                            if(parent.hasClass('input-group'))
                                parent.prev().removeClass('c-error');
                        }
                    }
                }
            });

            // For radios
            $('#'+formId+' input[type="radio"]').each(function(){

                if($(this).data('required') !== undefined){

                    var name = $(this).attr('name');
                    var parent = $(this).parent().parent().parent();

                    $(this).on('change', function(){
                        parent.removeClass('c-error');
                    });

                    if(!$('input[name="'+name+'"]:checked').val()){
                        hasErrors = true;
                        parent.addClass('c-error');
                    } else {
                        parent.removeClass('c-error');
                    }
                }
            });

            // For checkboxes
            $('#'+formId+' input[type="checkbox"]').each(function(){

                if($(this).data('required') !== undefined){

                    var name = $(this).attr('name');
                    var parent = $(this).parent().parent().parent();

                    $(this).on('change', function(){
                        parent.removeClass('c-error');
                    });

                    var chkArray = [],
                        selected;
                    $('input[name="'+name+'"]:checked').each(function() {
                        chkArray.push($(this).val());
                    });
                    selected = chkArray.join(',') ;

                    if(selected.length < 1){
                        hasErrors = true;
                        parent.addClass('c-error');
                    } else
                        parent.removeClass('c-error');
                }
            });

            if(hasErrors){
                toReturn = {'success':false, 'msg': obj_Messages.emptyfields};
                return toReturn;
            } else {

                // Specific validation
                // For numbers
                var hasNotANumberError = false;
                $('#'+formId+' input[type="number"]').each(function(){
                    if($(this).attr('max') !== undefined && $(this).attr('max')){

                        var value = $(this).val(),
                            max = $(this).attr('max'),
                            parent = $(this).parent();
                        if(parseInt(value) > max){
                            parent.addClass('c-error');
                            if(parent.hasClass('input-group'))
                                parent.prev().addClass('c-error');
                            hasNotANumberError = true;
                        } else {
                            parent.removeClass('c-error');
                            if(parent.hasClass('input-group'))
                                parent.prev().removeClass('c-error');
                            hasNotANumberError = false;
                        }
                    }
                });

                if(hasNotANumberError){

                    toReturn = {'success':false, 'msg': obj_Messages.msg_notanumber};
                    return toReturn;

                } else {
                    toReturn = {'success':true};
                    return toReturn;
                }
            }
        }
    }

    $('form').on('submit', function(e){
        if($(this).attr('id') == 'orderform')
            return false;

        e.preventDefault();
        showSpinner();

        // noScroll.on();

        // Als het een product buy button is
        if ($(this).hasClass('c-product-buybutton')){
            // submit form without Ajax
            event.currentTarget.submit();
            return;
        }

        // Als het een zoeken is
        if ($(this).data('confirm')){
            if(!$(this).data('confirmed')){
                showConfirm($(this).data('confirm'), function(){
                    $(this.form).data('confirmed', true);
                    $(this.form).submit();
                }, {form:this}, function(){
                }, {});
                return;
            }
        }

        // Validate fields
        var form = $(this),
            formId = form.attr('id'),
            obj_Messages = JSON.parse($('#formmessages').html());

        var isModal = $(form).find('input[name="isModal"]').val() == 1 ? true : false;
        var isModalID = isModal ? $(form).closest('div[data-type="modal"]').attr('id') : false;

        var validated = validateForm(this);
        if(!validated.success){
            if(isModal){
                showAlertFormInModal('error', validated.msg, form);
            } else {
                showAlert('error', validated.msg);
            }
        } else {

            // Als het een modal product schedule buy is
            if ($(this).hasClass('modal-product-schedule')){
                var thisId = $(this).attr('id');
                var modalId = thisId.replace('form-','');
                $('#'+modalId).hide();

                // submit form without Ajax
                event.currentTarget.submit();
                return;
            }

            // Validation is ok, we can send it to the server

            // Get the post data
            var data = $(this).serialize(),
                directredirect = $(this).find('input[name="directredirect"]').val(), // use redirect if you want to redirect after on click confirmbutton
                successMessage = $(this).find('input[name="successMessage"]').val(),
                returnurl = $(this).find('input[name="returnurl"]').val(),
                action = $(this).attr('action'),
                submitwithoutajax = $(this).find('input[name="submitwithoutajax"]').val(),
                eventCategory = $(this).find('input[name="submit_eventCategory"]').val(),
                eventAction = $(this).find('input[name="submit_eventAction"]').val(),
                eventLabel = $(this).find('input[name="submit_eventLabel"]').val(),
                eventValue = $(this).find('input[name="submit_eventValue"]').val();

            if(eventAction == 'submit'){
                gtag('event', eventAction, {
                    'event_category': eventCategory,
                    'event_label': eventLabel,
                    'value': eventValue
                });
            }

            if(!action && !submitwithoutajax){ // general config setting.
                // Send it to the server
                // if action in hidden field, dan wordt hij naar Craft controller action gestuurd (Door Craft)
                //var is = $(this).find('input[name="action"]').val();
                //if(url){
                $.ajax({
                    type: "POST",
                    url: '/',
                    data: data,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    dataType: "json",
                    contentType: "application/json"
                }).done(function(response) {
                    if(!response.success){
                        if(response.error) {
                            // Craft retourneert 'errorCode' vanuit core (bij User forms)
                            // Vertaling is niet nodig.
                            if(response.error){
                                showAlert('error', response.error);
                            } else {
                                // response.error will be an object containing any validation errors that occurred, indexed by field name
                                // e.g. response.error.fromName => ['From Name is required']
                                if(typeof response.error.g-recaptcha-response != undefined){
                                    if(isModal) {
                                        showAlertFormInModal('error', obj_Messages.general+' (3124)', form);
                                    } else {
                                        showAlert('error', obj_Messages.general+' (3124)');
                                    }
                                } else {
                                    if(isModal) {
                                        showAlertFormInModal('error', obj_Messages.general+' (1000)', form);
                                    } else {
                                        showAlert('error', obj_Messages.general + ' (1000)');
                                    }
                                }
                            }
                        } else {
                            if(isModal) {
                                showAlertFormInModal('error', obj_Messages.general+' (1000)', form);
                            } else {
                                showAlert('error', obj_Messages.general+' (1000)');
                            }
                        }
                        if(response.errors){ // Bij register form
                            var a_Messages = [];
                            for (var field in response.errors){
                                $('#'+formId+' #'+field).parent().addClass('c-error');
                                if(response.errors[field])
                                    a_Messages.push(response.errors[field]);
                            }
                            if(isModal){
                                showAlertFormInModal('error', a_Messages.join('<br />'), form);
                            } else {
                                showAlert('error', a_Messages.join('<br />'));
                            }
                        }
                    } else {
                        if(directredirect){
                            directredirect = directredirect.replace(/^\//g, '');
                            window.location.href = directredirect;
                        } else {
                            // response.returnUrl comes from Craft userlogin forms.
                            var returnUrl = response.returnUrl ? response.returnUrl : returnurl;

                            if(successMessage) {
                                showAlert('success', successMessage, returnUrl, isModalID);
                            } else {
                                showAlert('success', obj_Messages.success, returnUrl, isModalID);
                            }
                        }
                    }
                }).fail(function(response) {
                    if(isModal){
                        showAlertFormInModal('error', obj_Messages.general+' (1002)', form);
                    } else {
                        showAlert('error', obj_Messages.general+' (1002)'); // random foutcode
                    }
                });

            } else {
                // submit form without Ajax
                event.currentTarget.submit();
                var domainname = $('script#domainname').text().replace(/\"/g, "");

                if (action && action[0] != '/' && !action.startsWith(domainname)) {
                    if(isModal){
                        showAlertFormInModal('success', obj_Messages.success, form);
                    } else {
                        showAlert('success', obj_Messages.success);
                    }
                } // Anders is het namelijk een interne submit.
            }
        }
    });

    function showAlert(type, message, buttonUrl, hideModalID){ // type can be: success, error, info
        hideSpinner();
        if(hideModalID){
            $('#'+hideModalID).remove();
            $('div[modal-backdrop]').remove();
        }

        var thisID = 'alert-'+type;

        //var backdropClasses = 'bg-gray-900/50 fixed inset-0 z-40';
        const options = {
            // placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses:
                'bg-gray-900/50 fixed inset-0 z-40',
            closable: true,
            onShow: () => {
                $('div#alert-'+type).find('p[data-message]').text(message);
            },
        };

        const instanceOptions = {
            id: thisID,
            override: true
        };

        const $targetModal = document.getElementById(thisID);
        const modal = new Modal($targetModal, options, instanceOptions);

        if(buttonUrl){
            $('div#alert-'+type).find('button').click(function () {
                buttonUrl = buttonUrl.replace(/^\//g, '');
                window.location.href = buttonUrl;
            });
        } else {
            $('div#alert-'+type).find('button').click(function () {
                modal.hide();
            });
        }
        modal.show();
    }

    function showAlertFormInModal(type, message, form){
        hideSpinner();
        $(form).find('div[data-alert-modal]').find('[data-'+type+']').each(function(){
            var attr = $(this).attr('data-message');
            if (typeof attr !== 'undefined' && attr !== false) {
                $(this).text(message);
            }
        }).show();

        if(type === 'success'){
            var formId = $(form).attr('id');
            $(form).find('a[data-formsubmit="'+formId+'"]')
                .addClass('bg-extendum_grey-light')
                .addClass('border-extendum_grey-light')
                .addClass('cursor-default')
                .removeClass('hover:opacity-50')
                .removeClass('cursor-pointer')
                .off('click');
        }
    }




// GENERAL JS //
// 

// $('#hamburger').click(function () {
//     showOffCanvas();
//
//     $('#offcanvas_close, #offcanvas_mask').click(function () {
//         hideOffCanvas();
//     });
// });
//
// function hideOffCanvas() {
//     $('#offcanvas_panel').addClass('-translate-x-full');
//     $('#offcanvas_mask').addClass('-translate-x-full');
//     $('#offcanvas_close').hide();
// }
//
// function showOffCanvas() {
//     $('#offcanvas_mask').show();
//     setTimeout(function () {
//         $('#offcanvas_mask').removeClass('-translate-x-full');
//         setTimeout(function () {
//             $('#offcanvas_close').show();
//             $('#offcanvas_panel').removeClass('-translate-x-full');
//         }, 100);
//     }, 300);
// }

    // Scroll to top
    $('div[data-scrolltotop]').click(function(){
        window.scrollTo(0,0);
    });

    $('div[data-bodycontent] ul li ul i').each(function(){
        $(this).removeClass('fa-circle').addClass('fa-minus');
    });


/**
 * STRIPE
 * -----------------------------------------------------------------------------------------------------------------
 */

    var stripeKey = $('script#stripepublishkey').text();
    var stripe = Stripe(stripeKey,{'apiVersion':'2020-08-27'});

    // var style = {
    //     base: {
    //         colorPrimary: '#0A91D2',
    //         colorTextPlaceholder: '#cacaca',
    //         colorBackground: '#ffffff',
    //         colorText: '#333333',
    //         borderRadius: '4px',
    //         // color: '#876543',
    //         lineHeight: '18px',
    //         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //         fontSmoothing: 'antialiased',
    //         fontSizeBase: '13px',
    //         '::placeholder': {
    //             color: '#cacaca'
    //         }
    //     },
    //     invalid: {
    //         color: '#cc0000',
    //         iconColor: '#cc0000'
    //     }
    // };

    var style = {
        base: {
            // color: '#0A91D2',
            colorPrimary: '#0A91D2',
            colorTextPlaceholder: '#cacaca',
            colorText: '#333333',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSizeBase: '13px',
            fontSize: '13px',
            '::placeholder': {
                color: '#cacaca'
            }
        },
        invalid: {
            color: '#cc0000',
            iconColor: '#cc0000'
        }
    };

    if($('#card-element').length){
        var elements = stripe.elements();
        var cardElement = elements.create('card', {hidePostalCode: true, style: style});
        cardElement.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        cardElement.addEventListener('change', function(event) {
            if (event.error) {
                showPaymentError(event.error.message,'card')
            } else {
                hidePaymentError('card')
            }
        });
    }

    if($('#ideal-element').length){
        var elements = stripe.elements();
        var idealElement = elements.create('idealBank', {style: style});
        idealElement.mount('#ideal-element');

        // Handle real-time validation errors from the card Element.
        idealElement.addEventListener('change', function(event) {
            if (event.error) {
                showPaymentError(event.error.message,'ideal')
            } else {
                hidePaymentError('ideal')
            }
        });
    }

    // Toggle shipping_address_cnt
    if($('#shipping_is_billing').length){
        var form = $('form#orderform');
        $('#shipping_is_billing').change(function() {
            if (this.checked){
                $('#shipping_address_cnt').hide();
                form.find('input[name="metadata[shipping_address]"]').data('required', false);
                form.find('input[name="metadata[shipping_number]"]').data('required', false);
                form.find('input[name="metadata[shipping_postal_code]"]').data('required', false);
                form.find('input[name="metadata[shipping_city]"]').data('required', false);
                form.find('input[name="metadata[shipping_country]"]').data('required', false);

                form.find('input[name="metadata[shipping_address]"]').parent().removeClass('c-error');
                form.find('input[name="metadata[shipping_number]"]').parent().removeClass('c-error');
                form.find('input[name="metadata[shipping_postal_code]"]').parent().removeClass('c-error');
                form.find('input[name="metadata[shipping_city]"]').parent().removeClass('c-error');
                form.find('input[name="metadata[shipping_country]"]').parent().removeClass('c-error');
            } else {
                $('#shipping_address_cnt').show();

                form.find('input[name="metadata[shipping_address]"]').data('required', true);
                form.find('input[name="metadata[shipping_number]"]').data('required', true);
                form.find('input[name="metadata[shipping_postal_code]"]').data('required', true);
                form.find('input[name="metadata[shipping_city]"]').data('required', true);
                form.find('input[name="metadata[shipping_country]"]').data('required', true);
            }
        });
    }

    // placeholder color selectbox voor landen
    $('select.land').on('change', function() {
        if(this.value)
            $(this).css('color', '#a47328');
        else
            $(this).css('color', '#999');
    });

    $('form#orderform').on('submit', function(e){
        console.log('submit orderform');
        e.preventDefault();
        showSpinner();

        var form = $(this);
        var total = form.find('input[name="total"]').val();
        var free = total > 0 ? false : true;

        // get payment method
        if(!free) {
            var paymentMethod = form.find('#paymentmethod-card').hasClass('hidden') ? 'ideal' : false;
            if (!paymentMethod)
                paymentMethod = form.find('#paymentmethod-ideal').hasClass('hidden') ? 'card' : false;

            $("<input />").attr("type", "hidden")
                .attr("name", "paymentmethod")
                .attr("value", paymentMethod)
                .appendTo(form);

            if (paymentMethod === 'card')
                if (!validateElement(cardElement))
                    return false;
        } else {
            var paymentMethod = 0;
        }

        var email                   = form.find('input[name="email"]').val();
        var firstName               = form.find('input[name="firstname"]').val();
        var lastName                = form.find('input[name="lastname"]').val();
        var name = firstName.length ? firstName +' '+ lastName : lastName;

        var isSubscription          = form.find('input[name="subscription"]').val();
        var isOnetime               = form.find('input[name="onetime"]').val();

        var productids              = $("input[name='productids[]']").map(function(){return $(this).val();}).get().join(',');
        var coursescheduleids       = $("input[name='coursescheduleids[]']").map(function(){return $(this).val().length ? $(this).val() : null;}).get().join(',');

        var currency                = form.find('input[name="currency"]').val();
        var description             = form.find('input[name="title"]').val();
        var quantity                = form.find('input[name="quantity"]').val();
        var applied_discount_code   = form.find('input[name="appliedDiscountCode"]').val();
        var metadata                = getMetaData(form, 1);

        var returnUrl               = window.location.protocol+'//'+window.location.hostname+'/stripe/return/';
        var succesUrl               = window.location.protocol+'//'+window.location.hostname+'/checkout/success/';
        var failedUrl               = window.location.protocol+'//'+window.location.hostname+'/checkout/failed/';

        if(isSubscription === '1'){
            //console.log('is Subscription');

            var subscriptionplanid = form.find('input[name="subscriptionPlanId"]').val();
            var amount             = form.find('input[name="subscriptionPrice"]').val() * 100;

            if(paymentMethod === 'card'){

                // Stappen zijn.
                // -------------
                // 1) Create payment method
                // 2) Create customer
                // 3) Set the default payment method on the subscription

                createPaymentMethod({
                    'type' : 'card',
                    'element' : cardElement
                }, function(response){
                    log({'message' : 'stripe.js :: payment method is created'});

                    var paymentmethodid = response.id;

                    // Create customer
                    createCustomer({
                        'email' : email,
                        'name' : name,
                        'product_id' : productid,                       // nodig om te achterhalen of de user moet kunnen inloggen ed
                        'pricingplan_id' : pricingplanid,               // nodig om te achterhalen of de user moet kunnen inloggen ed
                        'subscriptionplan_id' : subscriptionplanid,     // nodig om te achterhalen of de user moet kunnen inloggen ed
                        'paymentmethod_id' : response.id,
                    }, function(response){
                        log({'message' : 'stripe.js :: customer is created'});

                        //console.log(response); // customer

                        subscribeToPlan({
                            'subscription_plan' : subscriptionplanid,
                            'customer' : response.id,
                            'default_payment_method' : paymentmethodid,
                            'metadata' : metadata,
                            'product_id' : productid,
                            'pricingplan_id' : pricingplanid,
                            'payment_method' : 'card'
                        }, function(response){
                            log({'message' : 'stripe.js :: customer is subscribed to plan'});

                            // TODO: Niet de hele response retourneren (We hebben alleen bepaalde waardes nodig)
                            // console.log(response.status);
                            // console.log(response);

                            if(response.status === 'incomplete' && response.latest_invoice.payment_intent){
                                // Handle any SCA
                                handleAuth({
                                    'client_secret' : response.latest_invoice.payment_intent.client_secret,
                                    'type' : 'card'
                                }, function(resp){
                                    //showAlert('success', 'Yes, Ook hier nog successurl inbakken.');
                                    window.location.href = succesUrl+response.metadata.order_id; // response wordt hierboven geset

                                }, function(response){
                                    log({'message' : '[ERROR] :: stripe.js :: customer could not be subscribed to plan'});

                                    if(response.error.message !== undefined)
                                        log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                                    showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                                });

                            } else if(response.status === 'active') {
                                window.location.href = succesUrl+response.metadata.order_id;
                                //showAlert('success', 'Yes, Ook hier nog successurl inbakken.');
                            }

                        },function(response){
                            log({'message' : '[ERROR] :: stripe.js :: customer could not be subscribed to plan'});

                            if(response.error.message !== undefined)
                                log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                            showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                        });

                    }, function(response){
                        log({'message' : '[ERROR] :: stripe.js :: customer could not be created'});

                        if(response.error.message !== undefined)
                            log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                        showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                    });

                }, function(response){
                    log({'message' : '[ERROR] :: stripe.js :: payment method could not be created'});

                    if(response.error.message !== undefined)
                        log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                    showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                });




                // create payment method
                //createPaymentMethod(paymentMethod, cardElement, email).then(createCustomer);
                // TODO: Nu uitzoeken hoe we een abonnement kunnen chargen. MOeten we dan ook een payment intent hebben? Of maken we een payment method?
            }
            else if(paymentMethod === 'ideal'){

                createCustomer({
                    'email' : email,
                    'name' : name,
                    'product_id' : productid
                }, function(customer){

                    createPaymentIntent({
                        'amount' : amount,
                        'currency' : currency,
                        'customer' : customer.id,
                        'description' : description,
                        'product_id' : productid, // to be able to validate the amount on the server
                        'pricingplan_id' : pricingplanid,
                        'quantity' : quantity, // to be able to validate the amount on the server
                        'applied_discount_code' : applied_discount_code,
                        'metadata' : metadata,
                        'payment_method' : 'ideal'
                    }, function(paymentIntent){

                        handleIdealPayment({
                            'client_secret' : paymentIntent.client_secret,
                            'ideal' : idealElement,
                            'billing_details' : getBillingDetails(),
                            'setup_future_usage' : 'off_session',
                            'return_url' : returnUrl+paymentIntent.metadata.order_id
                        }, function(result){}, function(response){
                            log({'message' : '[ERROR] :: stripe.js :: card could not be handled'});

                            if(response.error.message !== undefined)
                                log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                            showAlert('error', 'Something went wrong', failedUrl); //TODO: Betere foutmelding voor de user
                        });

                    }, function(response){
                        log({'message' : '[ERROR] :: stripe.js :: payment intent could not be created'});

                        if(response.error.message !== undefined)
                            log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                        showAlert('error', 'Something went wrong', failedUrl); //TODO: Betere foutmelding voor de user
                    });

                }, function(response){
                    log({'message' : '[ERROR] :: stripe.js :: customer could not be created'});

                    if(response.error.message !== undefined)
                        log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                    showAlert('error', 'Something went wrong', failedUrl); //TODO: Betere foutmelding voor de user
                });
            }
        } else if(isOnetime === '1'){

            var amount = form.find('input[name="amount"]').val();

            createCustomer({
                'email' : email,
                'name' : name,
                'product_ids' : productids,
                'free' : free
            }, function(customer){

                createPaymentIntent({
                    'amount' : amount,
                    'currency' : currency,
                    'customer' : customer.id,
                    'description' : description,
                    'product_ids' : productids, // to be able to validate the amount on the server
                    'schedule_ids' : coursescheduleids,
                    'quantity' : quantity, // to be able to validate the amount on the server
                    'applied_discount_code' : applied_discount_code,
                    'metadata' : metadata,
                    'payment_method' : paymentMethod
                }, function(paymentIntent){

                    if(!free) {
                        if (paymentMethod === 'card') {

                            handleCardPayment({
                                'client_secret': paymentIntent.client_secret,
                                'card': cardElement,
                                'billing_details': getBillingDetails(),
                                'shipping': getShippingDetails()
                            }, function (result) {
                                window.location.href = succesUrl + paymentIntent.metadata.order_id;
                                //showAlert('success', 'gelukt', succesUrl+paymentIntent.metadata.order_id);
                            }, function (response) {
                                log({'message': '[ERROR] :: stripe.js :: card could not be handled'});

                                if (response.error.message !== undefined)
                                    log({'message': '[ERROR] :: stripe.js :: ' + response.error.message});

                                //showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                                showAlert('error', 'Something went wrong');
                            });

                        } else if (paymentMethod == 'ideal') {
                            handleIdealPayment({
                                'client_secret': paymentIntent.client_secret,
                                'ideal': idealElement,
                                'billing_details': getBillingDetails(),
                                'shipping': getShippingDetails(),
                                'return_url': returnUrl + paymentIntent.metadata.order_id
                            }, function (result) {
                            }, function (response) {
                                log({'message': '[ERROR] :: stripe.js :: card could not be handled'});

                                if (response.error.message !== undefined)
                                    log({'message': '[ERROR] :: stripe.js :: ' + response.error.message});

                                showAlert('error', 'Something went wrong'); //TODO: Betere foutmelding voor de user
                            });
                        }
                    } else {
                        window.location.href = succesUrl + paymentIntent.metadata.order_id;
                    }

                }, function(response){
                    log({'message' : '[ERROR] :: stripe.js :: payment intent could not be created'});

                    if(response.error !== undefined && response.error.message !== undefined)
                        log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                    showAlert('error', 'Something went wrong (1360)'); //TODO: Betere foutmelding voor de user
                });

            }, function(response){
                log({'message' : '[ERROR] :: stripe.js :: customer could not be created'});

                if(response.error.message !== undefined)
                    log({'message' : '[ERROR] :: stripe.js :: '+ response.error.message});

                showAlert('error', 'Something went wrong (1369)'); //TODO: Betere foutmelding voor de user
            });
        }
    });

    function getMetaData(form, joined){
        var a_Values = form.serializeArray();
        var obj_Metadata = {};
        var obj = {};

        for (var key in a_Values) {
            var name = a_Values[key].name;
            var value = a_Values[key].value;
            if(name.match(/metadata\[(\w+)\]/)){
                name = name.replace(/metadata/,'');
                name = name.replace(/\[(\w+)\]/,'$1');
                if(name.match(/\[\]$/)){
                    name = name.replace(/\[\]/,'');
                    if(obj[name] === undefined)
                        obj[name] = [];
                    obj[name].push(value);
                } else
                    obj[name] = value;
            }
        }

        for (const prop in obj) {
            if(Array.isArray(obj[prop])){
                if(joined)
                    obj_Metadata[prop] = obj[prop].join('|');
                else {
                    if (obj[prop].length < 2)
                        obj_Metadata[prop] = obj[prop][0];
                    else
                        obj_Metadata[prop] = obj[prop];
                }
            } else
                obj_Metadata[prop] = obj[prop];
        }

        return obj_Metadata;
    }

    function validateElement(element){
        var id = element._parent.id;
        var container = $('#'+id);
        var type = id.replace(/-element/, '');

        if(!container.hasClass('StripeElement')){
            showAlert('error', 'something went wrong');
            return false;
        }

        if(container.hasClass('StripeElement--empty')){
            showAlert('error', 'You need to fill in your payment details');
            return false;
        }

        if(container.hasClass('StripeElement--invalid')){
            showAlert('error', 'There is something wrong with your payment details');
            return false;
        }

        return true;
    }

    function getAddress(form, type){
        var type = type ? type : 'billing';
        var form = form  ? form : $('form#orderform');


        if(type === 'billing'){
            var email = $(form).find('input[name="email"]').val();
            var name = $(form).find("input[name='name']").val();
            var address = $(form).find("input[name='metadata[address]']").val();
            var number = $(form).find("input[name='metadata[number]']").val();
            var city = $(form).find("input[name='metadata[city]']").val();
            var country = $(form).find("input[name='metadata[country]']").val();
            var state = $(form).find("input[name='metadata[state]']").val();
            var postal_code = $(form).find("input[name='metadata[postal_code]']").val();
        } else if(type === 'shipping'){
            var name = $(form).find("input[name='metadata[shipping_name]']").val();
            var address = $(form).find("input[name='metadata[shipping_address]']").val();
            var number = $(form).find("input[name='metadata[shipping_number]']").val();
            var city = $(form).find("input[name='metadata[shipping_city]']").val();
            var country = $(form).find("input[name='metadata[shipping_country]']").val();
            var state = $(form).find("input[name='metadata[shipping_state]']").val();
            var postal_code = $(form).find("input[name='metadata[shipping_postal_code]']").val();
        }

        var obj_Address = {};
        obj_Address['address'] ={
            'city' : city,
            'state' : state,
            'country' : country,
            'line1' : address + ' ' + number,
            'postal_code' : postal_code,
        };
        if(type === 'billing')
            obj_Address['email'] = email;
        obj_Address['name'] = name;

        return obj_Address;
    }

    function getBillingDetails(form){
        return getAddress(form);

        // var form = form  ? form : $('form#orderform');
        // var email = $(form).find('input[name="email"]').val();
        // var name = $(form).find("input[name='name']").val();
        //
        // var phone = $(form).find("input[name='metadata[phone]']").val();
        // var address = $(form).find("input[name='metadata[address]']").val();
        // var city = $(form).find("input[name='metadata[city]']").val();
        // var country = $(form).find("input[name='metadata[country]']").val();
        // var state = $(form).find("input[name='metadata[state]']").val();
        // var postal_code = $(form).find("input[name='metadata[postal_code]']").val();
        //
        // return {
        //     'address' : {
        //         'city' : city,
        //         'state' : state,
        //         'country' : country,
        //         'line_1' : address,
        //         'postal_code' : postal_code,
        //     },
        //     'email' : email,
        //     'name' : name,
        //     'phone' : phone,
        // };
    }

    function getShippingDetails(form) {
        return getAddress(form, 'shipping');
    }

    function handleCardPayment(a_Params, successCallback, errorCallback){
        // a_Params.client_secret
        // a_Params.card

        if(!validateRequired(a_Params, ['client_secret','card'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'handleCardPayment :: some of the required keys are missing'
                    }});
            else
                return false;
        }

        stripe.confirmCardPayment(a_Params.client_secret, {
            payment_method: {
                card: a_Params.card,
                billing_details: a_Params.billing_details
            },
        }).then(function(result) {
            // Handle result.error or result.paymentIntent
            if(result.error) {
                if(errorCallback)
                    errorCallback(result);
                else
                    showAlert('error', result.message);
            } else {
                // success
                if(successCallback)
                    successCallback(result);
                else
                    showAlert('success', 'gelukt');
            }
        });

    }

    function handleIdealPayment(a_Params, successCallback, errorCallback){
        // a_Params.client_secret
        // a_Params.ideal

        if(!validateRequired(a_Params, ['client_secret','ideal'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'handleIdealPayment :: some of the required keys are missing'
                    }});
            else
                return false;
        }

        stripe.confirmIdealPayment(a_Params.client_secret, {
            payment_method: {
                ideal: a_Params.ideal,
                billing_details: a_Params.billing_details
            },
            setup_future_usage : a_Params.setup_future_usage,
            return_url : a_Params.return_url
        }).then(function(result) {
            // Handle result.error or result.paymentIntent
            if(result.error) {
                if(errorCallback)
                    errorCallback(result);
                else
                    showAlert('error', result.message);
            } else {
                // success
                if(successCallback)
                    successCallback(result);
                else
                    showAlert('success', 'gelukt');
            }
        });

    }

    function createPaymentIntent(a_Params, successCallback, errorCallback){
        // a_Params.customer
        // a_Params.amount
        // a_Params.currency

        if(!validateRequired(a_Params, ['amount','currency'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'createPaymentIntent :: some of the required keys are missing'
                    }});
            else
                return false;
        }

        $.ajax({
            type: "POST",
            url: '/actions/product-module/order/create-payment-intent',
            data: JSON.stringify(a_Params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            dataType: "json",
            contentType: "application/json"
        }).done(function(response) {
            if(response.error) {
                if(errorCallback)
                    errorCallback(response);
                else
                    return false;
            } else {
                if(successCallback)
                    successCallback(response);
                else
                    return response;
            }
        }).fail(function(response) {
            if(errorCallback)
                errorCallback(response);
            else
                return false;
        });
    }

    function createPaymentMethod(a_Params, successCallback, errorCallback){
        // a_Params.element
        // a_Params.type

        if(!validateRequired(a_Params, ['element','type'])){
            if(errorCallback)
                errorCallback({'error' : {'message' : 'createPaymentMethod :: some of the required keys are missing'}});
            else
                return false;
        }

        stripe.createPaymentMethod(a_Params.type, a_Params.element, {
            billing_details : getBillingDetails()
        }).then(function(response){
            if(response.error){
                if(errorCallback)
                    errorCallback(response);
                else
                    return false;
            } else {
                if(successCallback)
                    successCallback(response.paymentMethod);
                else
                    return response;
            }
        })
    }

    function createCustomer(a_Params, successCallback, errorCallback){
        // a_Params.email
        // a_Params.name
        // a_Params.product_ids
        // a_Params.schedule_ids
        // a_Params.pricingplan_id
        // a_Params.paymentmethod_id


        if(!validateRequired(a_Params, ['email','name','product_ids'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'some of the required keys are missing'
                    }});
            else
                return false;
        }

        //var email = $('form#orderform').find('input[name="email"]').val();
        //var subscriptionPlanId = $('form#orderform').find('input[name="subscriptionPlanId"]').val();
        //var a_FormValues = $('form#orderform').serialize();

        $.ajax({
            type: "POST",
            url: '/actions/product-module/order/create-customer',
            data: JSON.stringify(a_Params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            dataType: "json",
            contentType: "application/json"
        }).done(function(response) {
            if(response.error) {
                if(errorCallback)
                    errorCallback(response);
                else
                    return false;
                //showAlert('error', response.message);
            } else {
                // success
                // console.log('createCustomer 1');
                // console.log(response); // response is Stripe customer

                if(successCallback)
                    successCallback(response);
                else
                    return response;

                //if(subscriptionPlanId)
                //    subscribeTo(subscriptionPlanId, response.id, paymentMethod)

                // if(onetime)
                // https://www.youtube.com/watch?v=ltv44zkpgo0&list=PLy1nL-pvL2M6IYfRCmhOPcyC70zJqFoCs&index=37

            }
        }).fail(function(response) {
            if(errorCallback)
                errorCallback(response);
            else
                return false;

            // if(response.error) {
            //     showAlert('error', response.message);
            // } else {
            //     showAlert('error', 'Something went wrong');
            // }
        });
    }

    function subscribeToPlan(a_Params, successCallback, errorCallback){
        // a_Params.subscription_plan
        // a_Params.customer
        // a_Params.default_payment_method
        // a_Params.productid
        // a_Params.pricingplanid

        if(!validateRequired(a_Params, ['subscription_plan','customer','default_payment_method','product_id','pricingplan_id'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'some of the required keys are missing'
                    }});
            else
                return false;
        }

        $.ajax({
            type: "POST",
            url: '/actions/product-module/order/subscribe-to-plan',
            data: JSON.stringify(a_Params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            dataType: "json",
            contentType: "application/json"
        }).done(function(response) {
            if(response.error) {
                if(errorCallback)
                    errorCallback(response);
                else
                    return false;
            } else {
                if(successCallback)
                    successCallback(response);
                else
                    return response;
            }
        }).fail(function(response) {
            if(errorCallback)
                errorCallback(response);
            else
                return false;
        });
    }

    function handleAuth(a_Params, successCallback, errorCallback){
        // a_Params.client_secret
        // a_Params.type

        if(!validateRequired(a_Params, ['client_secret','type'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'some of the required keys are missing'
                    }});
            else
                return false;
        }


        if(a_Params.type == 'card'){
            stripe.handleCardPayment(a_Params.client_secret).then(function(response){
                if(response.error){
                    if(errorCallback)
                        errorCallback(response);
                    else
                        return false;
                } else {
                    if(successCallback)
                        successCallback(response);
                    else
                        return response;
                }
            })
        }
    }

    function validateRequired(a_Params, a_Keys){
        for(var i=0; i<a_Keys.length; i++){
            if(!a_Params.hasOwnProperty(a_Keys[i]))
                return false;
        }
        return true;
    }

    function showPaymentError(msg, type){
        var errorCnt = $('#'+type+'-error');
        errorCnt.text(msg);
    }

    function hidePaymentError(type){
        var errorCnt = $('#'+type+'-error');
        errorCnt.text()
    }


    /**
     * UTILITIES
     * -----------------------------------------------------------------------------------------------------------------
     */
    $('a[data-activatespinner]').click(function(){
        showSpinner();
    });

    function log(a_Params){
        if(!validateRequired(a_Params, ['message'])){
            if(errorCallback)
                errorCallback({'error' : {
                        'message' : 'log :: some of the required keys are missing'
                    }});
            else
                return false;
        }

        $.ajax({
            type: "POST",
            url: '/actions/product-module/order/log',
            data: JSON.stringify(a_Params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            dataType: "json",
            contentType: "application/json"
        }).done(function(response) {
            if(response.error) {
                return false;
            } else {
                return true;
            }
        }).fail(function(response) {
            return false;
        });
    }

    function showSpinner(msg) {
        if (msg) {
            $('#mask_spinner_spinner_text').text(msg);
        } else {
            $('#mask_spinner_spinner_text').hide();
        }

        $('#mask_spinner').show();
        $('#mask_spinner_spinner').show();
    }

    function hideSpinner() {
        $('#mask_spinner_spinner_text').text('');
        $('#mask_spinner_spinner_text').hide();
        $('#mask_spinner').hide();
        $('#mask_spinner_spinner').hide();
    }
});


